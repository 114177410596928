<template>
  <!-- 新增修改资产信息弹窗 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    :title="dialogTitle"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '140px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="addFormData"
      >
      </base-form>
    </div>
    <template slot="footer">
      <base-button label="提 交" @click="submit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false;"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>

import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { assetsInfoFrom } from '../utils/make-config'
export default {
  components: { baseForm, BaseDialog, BaseButton },
  props: {
    visible: Boolean,
    dialogTitle: String
  },
  data () {
    return {
      addQueryParas: { validTimeUnit: '' }
    }
  },
  computed: {
    addForm () {
      return assetsInfoFrom(this)
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    currentVisible () {
      this.$nextTick(() => {
        this.$refs.addFormData.clearValidate()
      })
    }
  },
  methods: {
    // 提交新增修改
    submit () {
      this.$refs.addFormData.validate(valid => {
        if (valid) {

        }
      })
    }
  }
}
</script>
